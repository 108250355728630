<template>
  <div class="vh100 ml-preview-page-small">
    <div
      v-if="!show"
      class="vh100 d-flex justify-content-center align-items-center"
    >
      <SwipeButton
        ref="swipeButton"
        initialInstructionText="потяните, чтобы открыть"
        class="ml-swipe-button"
        @actionConfirmed="onActionConfirmed"
      />
    </div>
    <div style="width: 100%" v-show="show">
      <div class="mloyalty-panel-header-2 px-8 ">
        <a
          href="#"
          class="d-flex align-items-center ml-title-14-20 ml-text-grey"
          @click.prevent="goBack"
        >
          <img src="@/assets/img/arrow-left.png" alt="" class="mr-2" />
          Назад
        </a>
        <div class="ml-text-16-24-600 flex-grow-1 text-center">
          Предпросмотр
        </div>
      </div>
      <img
        class="ml-confeti"
        ref="confeti"
        src="@/assets/img/confetti.png"
        alt=""
      />
      <!-- class="mloyalty-perview-card mloyalty-perview-card-shrink"
      -->
      <div ref="preview" class="ml-perview-card">
        <div class="pb-3 px-8">
          <div class="row">
            <div
              class="col-12 text-center d-flex flex-column align-items-center"
            >
              <img v-if="sellerLogo" class="mt-3" :src="sellerLogo" alt="" />
              <div class="ml-preview-page_title1 mt-4">
                Сертификат на {{ price }}₽
              </div>
              <!-- <div class="ml-title1-24-32-600 mb-2">на {{ price }} ₽</div> -->
              <div class="ml-title1-24-32-600 mb-2 m-title-hidden">
                «РивГош»
              </div>
              <div class="ml-title3-14-24 mt-2 mb-1">
                Действует до 29.04.2020
              </div>
              <div class="ml-preview-page_congratulation mt-3">
                <!-- С днем рождения! Желаю крепкого здоровья, удачи, благополучия,
                добра, радости, любви, счастья, хорошего настроения, улыбок,
                ярких впечатлений. Пусть тепло и уют всегда наполняют твой дом,
                пусть солнечный свет согревает в любую погоду, при одной мысли о
                них. -->
                {{ congratulation }}
              </div>
            </div>
            <div class="col-12 d-flex flex-column align-items-center">
              <!-- <img
                src="@/assets/img/example/riv-gosh-card.png"
                alt=""
                class="mb-8 mloyalty-certificate-image"
              /> -->
              <v-img
                :aspect-ratio="16 / 11"
                class="ml-img-rounded mb-10 mt-2 ml-certificate-image"
                :src="image"
              >
                <div class="ml-basket-price-label2">{{ price }}₽</div>
              </v-img>
              <!-- <img
                src="@/assets/img/example/riv-gosh-bar-code.png"
                class="mb-5"
                height="70"
                width="300"
                alt=""
              /> -->
              <barcode
                :value="12345678"
                ref="barcode"
                :lineColor="'black'"
                :text="' '"
                :width="3"
              >
                Show this if the rendering fails.
              </barcode>
              <div class="ml-preview-page_card-number mb-3 ml-text-grey">
                Номер карты: 126324789743873
              </div>
              <div class="ml-preview-page_card-pin-code mb-4">
                PIN-код: 678 579
              </div>
            </div>
            <ImageCanvas />
            <div class="col-12  d-flex flex-column align-items-center">
              <a
                class="ml-silver-btn mb-4 ml-preview-page_preview-btn w100"
              >
                Распечатать
              </a>
              <a
                class="ml-silver-btn mb-4 ml-preview-page_preview-btn w100"
              >
                Сохранить PDF
              </a>
              <a
                class="ml-silver-btn mb-4 ml-preview-page_preview-btn w100"
              >
                Отправить на E-mail
              </a>
            </div>
          </div>
          <div>
            <MlSlideBlock class="mb-4">
              <template #title>Как использовать, и где действует?</template>
              <template #body>
                <div class="how-use px-3 pt-6" style="border: none">
                  <div class="item mb-2">
                    <div class="numb">1</div>
                    <div
                      class="ml-text-14-24-500 ml-text-grey pl-1"
                      v-linkified:options="{ className: 'ml-link' }"
                    >
                      Сохраните сертификат и <br />
                      PIN-код
                    </div>
                  </div>
                  <div class="item mb-2">
                    <div class="numb">2</div>
                    <div
                      class="ml-text-14-24-500 ml-text-grey pl-1"
                      v-linkified:options="{ className: 'ml-link' }"
                    >
                      Выберите магазин, в котором можно <br />
                      использовать сертификат
                    </div>
                  </div>
                  <div class="item mb-2">
                    <div class="numb">3</div>
                    <div
                      class="ml-text-14-24-500 ml-text-grey pl-1"
                      v-linkified:options="{ className: 'ml-link' }"
                    >
                      Сообщите или введите в магазине номер <br />
                      сертификата и PIN-код
                    </div>
                  </div>
                  <div class="item">
                    <div class="numb">4</div>
                    <div
                      class="ml-text-14-24-500 ml-text-grey pl-1"
                      v-linkified:options="{ className: 'ml-link' }"
                    >
                      Оплатите всю или часть покупки <br />
                      сертификатом
                    </div>
                  </div>
                  <div class="ml-divider my-4"></div>
                  <div>
                    <div class="ml-text-16-22-500 ml-text-grey mb-3">
                      Нужно больше информации
                    </div>
                    <a
                      href="#"
                      @click.prevent=""
                      style="width: 146px"
                      class="ml-silver-btn px-0  ml-text-grey1"
                    >
                      Узнать больше
                    </a>
                  </div>
                </div>
              </template>
            </MlSlideBlock>
          </div>
          <div>
            <MlSlideBlock>
              <template #title>Часто задаваемые вопросы</template>
              <template #body>
                <faq
                  style="margin-left: -32px; margin-right: -32px"
                  :isInit="true"
                  :isOwner="true"
                />
                <div class="ml-divider mb-4"></div>
                <div>
                  <div class="ml-text-16-22-500 ml-text-grey mb-3">
                    Не нашли ответ на вопрос
                  </div>
                  <a
                    href="#"
                    @click.prevent=""
                    style="width: 146px"
                    class="ml-silver-btn px-0 ml-text-grey1"
                  >
                    Задать вопрос
                  </a>
                </div>
              </template>
            </MlSlideBlock>
          </div>
          <div class="d-flex justify-content-center my-10">
            <a
              href="#"
              class="ml-social-btn mr-2"
              @click.prevent="openBotDialog('telegram')"
            >
              <img src="@/assets/img/default/social-icon-tg.svg" alt="" />
            </a>
            <a
              href="#"
              class="ml-social-btn mr-2"
              @click.prevent="openBotDialog('whatsapp')"
            >
              <img src="@/assets/img/default/social-icon-whatsapp.svg" alt="" />
            </a>
            <a
              href="#"
              class="ml-social-btn"
              @click.prevent="openBotDialog('viber')"
            >
              <img src="@/assets/img/default/social-icon-viber.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwipeButton from '@/components/UI/SwipeButton'
import faq from '@/components/Burger/Pages/FAQ'
import { mapActions, mapState } from 'vuex'
import faqTypes from '@/store/faq/types'
import MlSlideBlock from '@/components/UI/default/MlSlideBlock'
import EmailDialog from '@/components/Modal/EmailDialog'
import SuccessDialog from '@/components/Modal/SuccessDialog'
import BotDialog from '@/components/Modal/Owner/BotDialog'
import Config from '@/config'
import ImageCanvas from '@/components/Owner/ImageCanvas'
import VueBarcode from 'vue-barcode'

const SELLER_CONFIG = {
  LOGO: 'logo',
  WEBSITE: 'website'
}

export default {
  components: {
    SwipeButton,
    faq,
    MlSlideBlock,
    ImageCanvas,
    barcode: VueBarcode
  },
  data: () => ({
    show: false
  }),
  computed: {
    ...mapState({
      preview: state => state.basket.preview,
      sellerConfig: state => state?.app?.config?.SertSeller
    }),
    sellerLogo() {
      const result = this.sellerConfig?.find(
        x => x.Name?.toLowerCase() === SELLER_CONFIG.LOGO && x.Shown
      )
      return result?.Value
    },
    price() {
      return this.preview?.price ?? 0
    },
    congratulation() {
      return this.preview?.congratulation
    },
    image() {
      return this.preview?.certificate?.img
    }
  },
  methods: {
    ...mapActions('faq', [faqTypes.GET_FAQS]),
    saveImage() {
      var link = document.createElement('a')
      link.download = 'certificate.jpg'
      link.href = document
        .getElementById('ml-owner-drawing-card')
        .toDataURL('image/jpeg')
      link.click()
    },
    openBotDialog(type) {
      console.log('open')
      this.$modal.show(
        BotDialog,
        {
          type
        },
        {
          ...Config?.defaultModal,
          width: 384
        }
      )
    },
    openEmailDialog() {
      this.$modal.show(
        EmailDialog,
        null,
        {
          ...Config?.defaultModal,
          width: 384
        },
        {
          closed: this.closedEmailDialog
        }
      )
    },
    closedEmailDialog(e) {
      this.$modal.show(
        SuccessDialog,
        { text: 'Сертификат отправлен на e-mail traceur-pk@yandex.ru' },
        {
          ...Config?.defaultModal,
          width: 384
        }
      )
    },
    goBack() {
      this.$router.push('/')
    },
    onActionConfirmed() {
      this.$refs.preview.classList.add('ml-perview-card-shrink')
      setTimeout(() => {
        this.show = true
        this.bounce()
        // this.$refs.swipeButton.reset()
      }, 300)
    },
    bounce() {
      setTimeout(() => {
        this.$refs.preview.classList.remove('ml-perview-card-shrink')
        this.$refs.confeti.classList.add('ml-confeti-bounce')
        setTimeout(() => {
          this.$refs.confeti.remove()
        }, 400)
      }, 100)
    }
  },
  mounted() {
    this[faqTypes.GET_FAQS]({
      isWidgetBurger: false,
      isWidgetPreview: true,
      isSertOwnerSite: false
    })
    // this.bounce()
  }
}
</script>
